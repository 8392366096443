function SuccStatusCard({InfoData}) {
    return(
        <div className="status_succ_card">
            <div className="succ_image">
                <span className="checkIcon">
                    <i className='bx bx-check-double'></i>
                </span>
            </div>
            <div className="succ_title">
                {InfoData.status}
            </div>
            <div className="succ_description">
                Оставьте, пожалуйста, отзыв во вкладке “Отзыв о Товаре”
            </div>
            <div className="succ_button">
                <a target="_blank" rel="noopener noreferrer" href="https://digiseller.market/info/">Оставить отзыв</a>
            </div>
        </div>
    )
}

export default SuccStatusCard;