function Info(){

    return(
        <main>
            <div className="main_wrapper">
                <div className="main_card">
                    <div className="card">
                        <div className="main_card">
                            <div className="card_row">
                                <div className="main_img">
                                    <i className='bx bxs-file-doc'></i>
                                </div>
                            </div>
                            <div className="card_row">
                        <span className="main_text">
                            <span>Дополнительная информация заполняется...</span>
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Info;