import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function Accounts() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAnimRoll, setIsAnimRoll] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);


    useEffect(() => {
        sendToServer();
    }, []);

    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'accounts'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                setTableData(responseData.table);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };
    const sendToServerData = (data, object) => {
        const apiUrl = ServerLink().EditGameUrl;

        const requestData = {
            object: object,
            data: data
        };
        // console.log(requestData);

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Data updated on server:', responseData);
                sendToServer();
                setTimeout(() => {
                    setIsAnimRoll(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };
    const handleDelete = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить этот элемент?")) {
            sendToServerData(itemId, 'deleteAccount');
        }
    };
    const  handleDeleteSession = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить сессию?")) {
            sendToServerData(itemId, 'deleteSession');
        }
    };
    const  handleDeleteFriends = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить всех друзей на аккаунте?")) {
            sendToServerData(itemId, 'deleteFriends');
        }
    };

    const handleProxyEdit = (event, itemId) => {
        const updatedValue = event.target.innerText;

        setTableData(prevTableData => {
            const itemIndex = prevTableData.findIndex(item => item.id === itemId);

            if (itemIndex !== -1 && updatedValue !== prevTableData[itemIndex].proxies) {
                const updatedTableData = [...prevTableData];
                updatedTableData[itemIndex].proxies = updatedValue;
                sendToServerData(updatedTableData[itemIndex], 'updateProxies');
                return updatedTableData;
            }

            return prevTableData;
        });
    };

    const handleAccountBalance = () => {
        const data = {
            object: "updateBalance"
        };

        try {
            const response = fetch(ServerLink().BufferUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                // const data = await response.json();
                // console.log(data);

            } else {
                // console.error("Ошибка при отправке данных:", response.statusText);
            }
        } catch (error) {
            // console.error("Ошибка при отправке данных:", error);
        }
    };
    const handleParentIdChange = (event, id) => {
        const newParentId = event.target.value;
        setSelectedParentId(newParentId);
        // console.log(id, newParentId);
        // Отправка данных на сервер
        const data = {
            id: id,
            parentId: newParentId // Замените на то, что нужно отправить
        };

        sendToServerData(data, 'updateParentId'); // Замените на объект и название объекта, подходящие вашей структуре данных
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <OptimizedTable tableData={tableData} isLoading={isLoading} handleProxyEdit={handleProxyEdit} handleDelete={handleDelete} handleDeleteSession={handleDeleteSession} isAnimRoll={isAnimRoll} setIsAnimRoll={setIsAnimRoll} handleAccountBalance={handleAccountBalance} selectedParentId = {selectedParentId} handleParentIdChange = {handleParentIdChange} handleDeleteFriends = {handleDeleteFriends} />
            )}
        </>
    );
}

const TableRow = React.memo(({ item, handleProxyEdit, handleDelete, handleDeleteSession, isAnimRoll,setIsAnimRoll, selectedParentId, handleParentIdChange, tableData, handleDeleteFriends }) => {

    return(
        <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.login}</td>
            <td>{item.region}</td>
            <td>{item.balance}</td>
            <td>
                <button onClick={() => {
                    handleDeleteSession(item.login)
                    setIsAnimRoll(true)
                }} className={`bx bx-refresh table_delete ${isAnimRoll ? 'animationRoll' : ''}`}></button>
            </td>
            <td>
                <button onClick={() => {
                    handleDeleteFriends(item.id)
                    setIsAnimRoll(true)
                }} className={`bx bx-refresh table_delete ${isAnimRoll ? 'animationRoll' : ''}`}></button>
            </td>
            {/*<EditableCell*/}
            {/*    value={item.proxies}*/}
            {/*    onBlur={(event) => handleProxyEdit(event, item.id)}*/}
            {/*/>*/}
            <td>
                <select
                    id="parentId"
                    name="parentId"
                    value={item.parentId}
                    onChange={(event) => handleParentIdChange(event, item.id)}
                >
                    <option value="">Нет замены</option>
                    {/* Здесь добавьте варианты выбора parentId на основе ваших данных */}
                    {tableData.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.id} | {item.login} | {item.region} | {item.balance}
                        </option>
                    ))}
                </select>
            </td>
            <td>
                <button onClick={() => handleDelete(item.id)} className="table_delete bx bx-trash"></button>
            </td>
        </tr>
    )
});

// const EditableCell = ({ value, onBlur }) => {
//     return(
//     <td>
//         <span
//             suppressContentEditableWarning={true}
//             contentEditable
//             onBlur={onBlur}
//             className={`editable_cell`}
//         >
//             {value}
//         </span>
//     </td>
// )};

const OptimizedTable = ({ tableData, isLoading, handleProxyEdit, handleDelete, handleDeleteSession, setIsAnimRoll, isAnimRoll, handleAccountBalance, selectedParentId, handleParentIdChange, handleDeleteFriends }) => (
    <>
        {isLoading ? (
            <Loader />
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Логин аккаунта</th>
                        <th>Регион</th>
                        <th>
                            <span>Баланс</span>
                            <span><button onClick={handleAccountBalance} className="bx bx-refresh headerFormButton"></button></span>
                        </th>
                        <th>Сессия</th>
                        <th>Друзья</th>
                        <th>Аккаунт замена</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id} item={item} handleProxyEdit={handleProxyEdit} handleDelete={handleDelete} handleDeleteSession={handleDeleteSession} setIsAnimRoll={setIsAnimRoll} isAnimRoll={isAnimRoll}  selectedParentId = {selectedParentId} handleParentIdChange = {handleParentIdChange} tableData = {tableData} handleDeleteFriends = {handleDeleteFriends} />
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
);

export default Accounts;
