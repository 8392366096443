import {Link} from 'react-router-dom';
function Main(){

    return(
        <main>
            <div className="main_wrapper">
                <div className="main_card">
                    <div className="card">
                        <div className="main_card">
                            <div className="card_row">
                                <div className="main_img">
                                    <i className='bx bxs-timer'></i>
                                </div>
                            </div>
                            <div className="card_row">
                        <span className="main_text">
                            <span><Link to="/">bikskey</Link></span>
                            <span>Система автоматической доставки игр</span>
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Main;