function Footer(){

    return(
        <footer>
            <div className="footer">
                © 2023 bikskey.ru. All Rights Reserved.
            </div>
        </footer>
    );
}

export default Footer;