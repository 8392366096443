import React from "react";
import { useAuth } from "../Components/AuthProvider";
import Control from "../Components/Control";
import LoginForm from "../Components/LoginForm";

function Admin() {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {isAuthenticated ? <Control /> : <LoginForm/>}
        </>
    );
}

export default Admin;
