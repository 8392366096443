const CardButtons = ({ modal, setModal }) => {

    return (
        <>
            <div className="card">
                <div className="card_row">
                    <button onClick={() => setModal({ ...modal, transactions: true })} className="btn_transactions">
                        <i className='bx bxs-cart'></i>
                    </button>
                    <button onClick={() => setModal({ ...modal, games: true })} className="btn_games">
                        <i className='bx bxs-joystick'></i>
                    </button>
                    <button onClick={() => setModal({ ...modal, accounts: true })} className="btn_accounts">
                        <i className='bx bxs-user'></i>
                    </button>
                </div>
            </div>
        </>
    );
};

export default CardButtons;
