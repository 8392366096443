import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";

function TransactionInfo() {
    const [searchActive, setSearchActive] = useState(false);
    const [transactionCode, setTransactionCode] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [transactionData, setTransactionData] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [accUrl, setAccUrl] = useState(transactionData.accUrl);
    const [isAnimationActive, setAnimationActive] = useState(false);


    useEffect(() => {
        sendToServer();
    }, []);

    const toggleSearch = () => {
        setSearchActive(!searchActive);
    };

    const sendRequest = () => {
        // console.log(transactionCode);
        const data = {
            data: transactionCode,
            object: "transactionCheck"
        };

        fetch(ServerLink().TableUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log('Data updated on server:', responseData);
                if (responseData === false) {
                    return
                }
                setTransactionData(responseData);
                setSelectedAccount(responseData.accountId)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };

    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'accounts'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData.table);
                setAccounts(responseData.table);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

            });
    };
    const handleAccUrlChange = (event) => {
        const newUrl = event.target.textContent;
        setAccUrl(newUrl);
    };

    const handleButtonClick = () => {
        const apiUrl = ServerLink().EditGameUrl;

        const data = {
            code: transactionCode,
            accUrl: accUrl
        }
        const requestData = {
            object: 'newProfileUrl',
            data: data
        };
        console.log(data)

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);
                // setAccounts(responseData.table);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

            });
    };

    const handleRetryButtonClick = () => {
        const apiUrl = ServerLink().RetryTransaction; // Замените на нужный URL для повторной выдачи

        const data = {
            code: transactionCode,
            accountId: selectedAccount
        };

        const requestData = {
            object: 'retryTransaction',
            data: data
        };
        // console.log(requestData);

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log('Retry response:', responseData);
                setAnimationActive(true);
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error retrying transaction:', error);
            });
    };

    return(
        <>
            <div className="card trans_info">
                <div className="card_row">
                    <div className="trans_header">
                        {searchActive ? (
                            <>
                                <span className="trans_input">
                                    <input placeholder="Код транзакции"
                                           type="text"
                                           value={transactionCode}
                                           onChange={(e) => setTransactionCode(e.target.value)}
                                            required/>
                                    <button className="bx bx-search" onClick={sendRequest}></button>
                                </span>
                                <span className="trans_search">
                                    <button className={`bx ${searchActive ? 'bx-x' : 'bx-search'}`} onClick={toggleSearch}></button>
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="trans_title">Поиск транзакции</span>
                                <span className="trans_search">
                                    <button className={`bx bx-search`} onClick={toggleSearch}></button>
                                </span>
                            </>
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <></>
                ) : (
                    <>
                        <div className="card_row">
                            <div className="trans_main">
                                <div className="trans_main_info">
                                    <div className="trans_info_left">
                                <span className="trans_info_title">
                                    Ссылка на профиль
                                </span>
                                        <span className="trans_info_link">
                                    <span onInput={handleAccUrlChange}
                                          onBlur={handleAccUrlChange} suppressContentEditableWarning={true} contentEditable={true}>
                                        {transactionData.accUrl}
                                    </span>
                                    <span>
                                        <button className="bx bx-send" onClick={handleButtonClick}></button>
                                    </span>
                                </span>
                                    </div>
                                    <div className="trans_info_right">
                                        <div className="trans_info_img">
                                            <i className="bx bx-user-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card_row">
                            <div className="trans_main">
                                <div className="trans_main_info">
                                    <div className="trans_info_left">
                                <span className="trans_info_title">
                                    {transactionData.name}
                                </span>
                                        <span className="trans_info_link">
                                    <span>
                                        {transactionData.appUrl}
                                    </span>

                                </span>
                                    </div>
                                    <div className="trans_info_right">
                                        <div className="trans_info_img">
                                            <i className="bx bx-joystick"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card_row">
                            <div className="trans_main">
                                <div className="trans_main_info">
                                    <div className="trans_info_left">
                                <span className="trans_info_title">
                                    {transactionData.date}
                                </span>
                                        <span className="trans_info_link">
                                    <span>
                                        {transactionData.status}
                                    </span>
                                </span>
                                    </div>
                                    <div className="trans_info_right">
                                        <div className="trans_info_img">
                                            <i className="bx bx-time-five"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card_row_button">
                            <select required name="accountId" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                                <option value="" disabled>Выберите аккаунт</option>
                                {accounts.map(account => (
                                    <option key={account.id} value={account.id}>
                                        {account.login} | {account.balance} | {account.region} {/* Здесь вы можете использовать любую информацию из аккаунта */}
                                    </option>
                                ))}
                            </select>
                            <button className={`${isAnimationActive ? 'animation' : ''}`} onClick={handleRetryButtonClick}>Повторная выдача</button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default TransactionInfo;