import React, { useState, useEffect } from "react";
import Transactions from "./Transactions";
import TransactionsQiwi from "./TransactionsQiwi";
import ServerLink from "./ServerLink";

function TransactionsTabs() {
    const [activeTab, setActiveTab] = useState("games");
    const [tableData, setTableData] = useState('Загрузка')

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        sendToServer();
    }, []);
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'walletBalance'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData.table[0])
                setTableData(responseData.table[0]);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    return (
        <>
            <div className="tabs_row">
                <button onClick={() => handleTabClick("games")} className={activeTab === "games" ? "active" : ""}>
                    Транзакции по играм
                </button>
                <button onClick={() => handleTabClick("qiwi")} className={activeTab === "qiwi" ? "active" : ""}>
                    <span>Пополнения Steam</span>
                    <span>
                        <span>{tableData.id}$</span>
                    </span>
                </button>
            </div>
            {activeTab === "games" ? <Transactions /> : <TransactionsQiwi />}
        </>
    );
}

export default TransactionsTabs;
