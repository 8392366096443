import React, { useState, useEffect } from 'react';
import GameImage from "../img/cs.png";
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function GameCard() {
    const [InfoData, setInfoData] = useState({
        title: '/',
        link: '/',
        image: '/'
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Get the uniquecode from the address bar
        const searchParams = new URLSearchParams(window.location.search);
        const uniquecode = searchParams.get('uniquecode');

        if (uniquecode) {
            // Send the uniquecode to the server
            setTimeout(() => {
                sendUniqueCodeToServer(uniquecode);
            }, 2000);
        } else {
            // If no uniquecode, set the gameData to "No transaction"
            setInfoData({
                title: 'Игра не найдена',
                link: '/',
                image: GameImage
            });
            setIsLoading(false);
        }
    }, []);

    // Function to send the uniquecode to the server
    const sendUniqueCodeToServer = (uniquecode) => {
        const apiUrl = ServerLink().SteamUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            uniquecode: uniquecode,
            object: 'game'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                setInfoData({
                    title: data.title,
                    link: data.link,
                    image: data.image
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error sending uniquecode to the server:', error);
                setIsLoading(false);
            });
    };

    return (
        <div className="card game_card">
            {isLoading ? (
                <Loader/>
            ):<>
                <div className="card_game_title">
                    <span></span>
                    <span><a target="_blank" rel="noopener noreferrer" href={InfoData.link}>
                        <span>{InfoData.title}</span>
                        </a></span>
                </div>
                <div className="game_image">
                    <img src={InfoData.image} alt="" />
                </div>
            </>}
        </div>
    );
}

export default GameCard;
