import Loader from "./Loader";
import React, {useEffect, useState} from "react";
import Steam from "../img/steam.png";
import ServerLink from "./ServerLink";

function QiwiCard () {
    const [isLoading, setIsLoading] = useState(true);
    const [InfoData, setInfoData] = useState({
        login: '/',
        amount: '/',
        code: '/'
    });
    useEffect(() => {
        // Get the uniquecode from the address bar
        const searchParams = new URLSearchParams(window.location.search);
        const uniquecode = searchParams.get('uniquecode');

        if (uniquecode) {
            // Send the uniquecode to the server
            setTimeout(() => {
                sendUniqueCodeToServer(uniquecode);
            }, 2000);
        } else {
            // If no uniquecode, set the gameData to "No transaction"
            setInfoData({
                login: 'error',
                amount: 'error',
                code: 'Юникод не найден'
            });
            setIsLoading(false);
        }
    }, []);
    const sendUniqueCodeToServer = (uniquecode) => {
        const apiUrl = ServerLink().QiwiUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            uniquecode: uniquecode,
            object: 'qiwi'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                setInfoData({
                    login: data.login,
                    amount: data.amount,
                    code: data.code
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error sending uniquecode to the server:', error);
                setIsLoading(false);
            });
    };

    return(
        <>
            <div className="card profile_card qiwi_card">
                {isLoading ? (
                        <Loader/>
                    ):
                    <>
                        <div className="profile_image">
                            <img src={Steam} alt=""/>
                        </div>
                        <div className="profile_title">
                            Логин: {InfoData.login}
                        </div>
                        <div className="profile_link">
                            Сумма: {InfoData.amount} руб.
                        </div>
                        <div className="profile_button">
                            <span>{InfoData.code}</span>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default QiwiCard;