import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function Transactions() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAll, setShowAll] = useState(false); // Флаг для отображения всех транзакций

    const handleShowAll = () => {
        setShowAll(true); // Устанавливаем флаг, чтобы показать все транзакции
    };

    useEffect(() => {
        sendToServer();
    }, []);

    const handleRefresh = () => {
        sendToServer();
    };
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'transactions'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                setTableData(responseData.table);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <OptimizedTable
                        handleRefresh={handleRefresh}
                        tableData={showAll ? tableData : tableData.slice(0, 100)} // Отображаем все транзакции или только первые 100
                    />
                    {!showAll && (
                        <div className="showAll_button">
                            <button onClick={handleShowAll}>Загрузить еще</button>
                        </div> // Кнопка для загрузки остальных транзакций
                    )}
                </>
            )}
        </>
    );
}

const TableRow = React.memo(({ item }) => (
    <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.code}</td>
        <TableCell value={item.name} />
        <td>{item.steamLogin}</td>
        <td>{item.region}</td>
        <TableCell value={item.acc_url} />
        <td>{item.purchasePrice}</td>
        <td>{item.date}</td>
        <td>{item.status}</td>
    </tr>
));

const TableCell = React.memo(({ value }) => (
    <td className="hidden">
        <span className="visibility_text">{value}</span>
        <span title="Нажмите, чтобы скопировать" className="hover_text">
            {value}
        </span>
    </td>
));

const OptimizedTable = ({ tableData, isLoading, handleRefresh }) => (
    <>
        {isLoading ? (
            <Loader />
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th>
                            <span>ID</span>
                            <span><button onClick={handleRefresh} className="bx bx-refresh headerFormButton"></button></span>
                        </th>
                        <th>Код транзакции</th>
                        <th>Название игры</th>
                        <th>Аккаунт выдачи</th>
                        <th>Регион</th>
                        <th>Ссылка на аккаунт</th>
                        <th>Цена покупки</th>
                        <th>Дата</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id} item={item} />
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
)

export default Transactions;
