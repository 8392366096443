import React from "react";
import Modal from "./Modal";

function ModalContent({ title, isOpened, onClose, children}) {
    return (
        <Modal
            Children = {children}
            Title={title}
            isOpened={isOpened}
            onModalClose={onClose}
        >
        </Modal>
    );
}

export default ModalContent;
