import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function Games() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        sendToServer();
    }, []);

    const handleRefresh = () => {
        sendToServer();
    };
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'games'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                setTableData(responseData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };
    const handleUrlEdit = (event, itemId) => {
        const updatedValue = event.target.innerText;

        setTableData(prevTableData => {
            const itemIndex = prevTableData.findIndex(item => item.id === itemId);

            if (itemIndex !== -1 && updatedValue !== prevTableData[itemIndex].app_url) {
                const updatedTableData = [...prevTableData];
                updatedTableData[itemIndex].app_url = updatedValue;
                // Отправляем данные на сервер
                sendToServerData(updatedTableData[itemIndex], 'updateGameUrl');
                return updatedTableData;
            }

            return prevTableData;
        });

        // console.log(updatedValue, itemId);
    };
    const handleNameEdit = ({ target: { innerText } }, itemId) => {
        setTableData(prevTableData => {
            const itemIndex = prevTableData.findIndex(item => item.id === itemId);

            if (itemIndex !== -1 && innerText !== prevTableData[itemIndex].name) {
                const updatedTableData = [...prevTableData];
                updatedTableData[itemIndex].name = innerText;
                sendToServerData(updatedTableData[itemIndex], 'updateGameName');
                console.log(updatedTableData[itemIndex], itemId);
                return updatedTableData;
            }

            return prevTableData;
        });
    };

    const sendToServerData = (data, object) => {
        const apiUrl = ServerLink().EditGameUrl;

        const requestData = {
            object: object,
            data: data
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Data updated on server:', responseData);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };
    const handleAccountChange = (event, currentItem) => {
        const newAccountId = event.target.value;
        const updatedTableData = tableData.map(item => {
            if (item.id === currentItem.id) {
                console.log(newAccountId, item.id);
                const data = {
                    newAccountId: newAccountId,
                    appId: item.id
                };
                sendToServerData(data, 'accountChange');
                return { ...item, account_id: newAccountId };
            }
            return item;
        });
        setTableData(updatedTableData);
    };
    const handleCheckboxChange = (event, currentItem) => {
        const updatedTableData = tableData.map(item => {
            if (item.id === currentItem.id) {
                console.log(currentItem.id, event.target.checked ? '1' : '0');
                const updatedItem = { ...item, close: event.target.checked ? '1' : '0' };

                // Вызываем функцию для отправки на сервер
                sendToServerData(updatedItem, 'updateCheckbox');

                return updatedItem;
            }
            return item;
        });
        setTableData(updatedTableData);
    };



    return (
        <>{isLoading ? (
            <Loader />
        ) : (
            <Table
                tableData={tableData}
                isLoading={isLoading}
                handleUrlEdit={handleUrlEdit}
                handleNameEdit={handleNameEdit}
                handleAccountChange={handleAccountChange}
                handleCheckboxChange={handleCheckboxChange}
                handleRefresh={handleRefresh}
            />
            )}
        </>
    );
}

const TableRow = React.memo(({ item, handleUrlEdit, handleCheckboxChange, handleAccountChange, handleNameEdit, handleRefresh}) => {
    const [isComponentVisible, setComponentVisible] = useState(false);
    const [newDigiseller, setNewDigiseller] = useState("");
    const [newMarkup, setNewMarkup] = useState("");
    const [newSellingPrice, setNewSellingPrice] = useState("");
    const [isAnimationActive, setAnimationActive] = useState(false);

    const toggleComponentDigi = () => {
        setComponentVisible(!isComponentVisible);
    };
    const handleNewDigisellerChange = (event) => {
        setNewDigiseller(event.target.innerText);
        // console.log(event.target);
    };
    const handleNewSellingPrice = (event, digiId) => {
        const newText = event.target.innerText;
        if (newText !== newSellingPrice) {
            setNewSellingPrice(newText);
            console.log(newText);
            sendToServerData(newText,digiId,'updateSellingPrice')
        }
    };
    const handleNewMarkup = (event, digiId) => {
        const newText = event.target.innerText;
        if (newText !== newMarkup) {
            setNewMarkup(newText);
            console.log(newText);
            sendToServerData(newText,digiId,'updateMarkup')
        }
    };

    const handleOldPriceEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.old_price) {
            sendToServerData(newText, itemId, 'updateOldPrice');
        }
    };

    const handleNewPriceEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.new_price) {
            sendToServerData(newText, itemId, 'updateNewPrice');
        }
    };

    const handleOldPriceRubEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.oldPriceRub) {
            sendToServerData(newText, itemId, 'updateOldPriceRub');
        }
    };

    const handleNewPriceRubEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.newPriceRub) {
            sendToServerData(newText, itemId, 'updateNewPriceRub');
        }
    };



    const handleEditDigisellerChange = (event, digiId) => {
        const newInputValue = event.target.innerText;

        if (newInputValue !== newDigiseller) {
            setNewDigiseller(newInputValue);
            // console.log(newInputValue, digiId);
            sendToServerData(newInputValue, digiId, 'editDigi')
        }
    };
    const handleDelete = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить этот элемент?")) {
            sendToServerData('data', itemId, 'deleteApp');
        }
    };

    const sendToServerData = (data, itemId, object) => {
        const apiUrl = ServerLink().EditGameUrl;

        const dataNew = {
            digiId: itemId,
            newDigi: data
        };

        const requestData = {
            object: object,
            data: dataNew
        };
        console.log(requestData)

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Data updated on server:', responseData);
                setAnimationActive(true);
                handleRefresh();
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };

    const currencySymbols = {
        RU: '₽',
        UA: '₴',
        AZ: '₼',
        KZ: '₸',
        USD: '$',

        // другие регионы и символы валюты
    };
    return(
        <tr key={item.id}>
            <td>{item.id}</td>
            <td>
            <>
                {Object.keys(item.markups).map((digiId) => (
                    <span key={digiId}>
                        <span className={`digi_placeholder ${isAnimationActive ? 'animation' : ''}`} >
                          <span title={item.markups[digiId].title} onFocus={(event) => setNewDigiseller(event.target.innerText)} onBlur={(event) => handleEditDigisellerChange(event, digiId)} className="digi_id" suppressContentEditableWarning={true} contentEditable>
                            {digiId}
                          </span>
                          <span>
                            <button onClick={(event) => sendToServerData(item.id, digiId, 'deleteDigi')} className="table_add delete bx bx-x"></button>
                          </span>
                        </span>
                        <span suppressContentEditableWarning={true} contentEditable onFocus={(event) => setNewMarkup(event.target.innerText)} onBlur={(event) => handleNewMarkup(event, digiId)} className={`digi_placeholder percent ${isAnimationActive ? 'animation' : ''}`} >
                          {item.markups[digiId].markups} {/* Используйте ключ digiId для доступа к соответствующему значению `markups` из ассоциативного массива `item.markups` */}
                        </span>
                      </span>
                ))}
                <>
                {isComponentVisible && (
                    <>
                        <span onInput={(event) => handleNewDigisellerChange(event)} suppressContentEditableWarning={true} contentEditable className="digi_placeholder">Новый Digiseller</span>
                        <button onClick={(event) => {sendToServerData(newDigiseller, item.id, 'newDigi'); toggleComponentDigi();}} className="table_add bx bx-plus"></button>
                    </>
                )}
                    <button onClick={toggleComponentDigi} className={`table_add bx ${isComponentVisible ? 'bx-x delete' : 'bx-plus'}`}></button>
                </>
            </>
            </td>
            <td className="hidden">
                <span className={`visibility_text ${isAnimationActive ? 'animation' : ''}`}>{item.app_url}</span>
                <span suppressContentEditableWarning={true} contentEditable onBlur={(event) => handleUrlEdit(event, item.id)} className="hover_text">{item.app_url}</span>
            </td>
            <td className="hidden">
                <span className={`visibility_text ${isAnimationActive ? 'animation' : ''}`}>{item.name}</span>
                <span suppressContentEditableWarning={true} contentEditable onBlur={(event) => handleNameEdit(event, item.id)} className="hover_text">
                {item.name}
            </span>
            </td>
            <td>
                <select value={item.account_id} onChange={(event) => handleAccountChange(event, item)}>
                    {Object.entries(item.accounts).map(([steamLogin, accountInfo]) => (
                        <option key={accountInfo.accountId} value={accountInfo.accountId}>
                            {steamLogin} | {accountInfo.region} | {accountInfo.balance}
                        </option>
                    ))}
                </select>
            </td>
            <td>{item.region}</td>
            <td><span
                    className={`editable_cell ${isAnimationActive ? 'animation' : ''}`}
                    onBlur={(event) => handleOldPriceEdit(event, item.id)}
                    suppressContentEditableWarning={true} contentEditable>
                {item.old_price}
                </span>
                {currencySymbols[item.region]} /
                <span className={`editable_cell ${isAnimationActive ? 'animation' : ''}`}
                      onBlur={(event) => handleOldPriceRubEdit(event, item.id)}
                      suppressContentEditableWarning={true} contentEditable>
                    {item.oldPriceRub}
                </span> ₽</td>
            <td>{item.discount}%</td>
            <td>
                <span className={`editable_cell ${isAnimationActive ? 'animation' : ''}`}
                       onBlur={(event) => handleNewPriceEdit(event, item.id)}
                       suppressContentEditableWarning={true} contentEditable>
                    {item.new_price}
                </span>
                {currencySymbols[item.region]} /
                <span className={`editable_cell ${isAnimationActive ? 'animation' : ''}`}
                   onBlur={(event) => handleNewPriceRubEdit(event, item.id)}
                   suppressContentEditableWarning={true} contentEditable>
                {item.newPriceRub}
            </span> ₽</td>
            <td>
            <span>
                {Object.entries(item.sellPrice).map(([digiId, price]) => (
                    <span key={digiId}>
                        <span className={`editable_cell ${isAnimationActive ? 'animation' : ''}`} suppressContentEditableWarning={true} contentEditable onFocus={(event)=> setNewSellingPrice(event.target.innerText)} onBlur={(event) => handleNewSellingPrice(event, digiId)}>{price}</span>
                        <span>₽ </span>
                    </span>
                ))}
            </span>
            </td>
            <td>
                <input
                    type="checkbox"
                    checked={item.close === '1'}
                    onChange={(event) => handleCheckboxChange(event, item)}
                />
            </td>
            <td>{item.day}</td>
            <td><button onClick={() => handleDelete(item.id)} className="table_delete bx bx-trash"></button></td>
            {/* Другие поля */}
        </tr>
    )


});

const Table = ({ tableData, isLoading, handleUrlEdit, handleCheckboxChange, handleAccountChange, handleNameEdit, handleRefresh}) => (
    <>
        {isLoading ? (
            <Loader />
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>
                            <span>ID от Digiseller</span>
                            <span>
                                <button onClick={handleRefresh} className="bx bx-refresh headerFormButton"></button>
                            </span>
                        </th>
                        <th>Ссылка на игру</th>
                        <th>Название игры</th>
                        <th>Аккаунт выдачи</th>
                        <th>Регион</th>
                        <th>Цена Steam</th>
                        <th>Скидка</th>
                        <th>Цена по скидке</th>
                        <th>Цена продажи</th>
                        <th>Выкл</th>
                        <th>Дата обновления</th>
                        <th>Удалить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id} item={item} handleUrlEdit={handleUrlEdit} handleCheckboxChange={handleCheckboxChange} handleAccountChange={handleAccountChange} handleNameEdit={handleNameEdit} handleRefresh={handleRefresh} />
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
)

export default Games;
