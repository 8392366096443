import React, { useState } from "react";
import { useAuth } from "./AuthProvider";
import SteamStatusCard from "./SteamStatusCard";

function LoginForm() {
    const [loginData, setLoginData] = useState({
        login: "",
        pass: "",
    });

    const [error, setError] = useState(null); // Добавляем состояние для ошибки

    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(loginData);
        } catch (error) {
            setError(error.message); // Если возникла ошибка, сохраняем ее в состояние
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <main>
            <div className="main_wrapper">
                <div className="main_auth">

                    <div className="main_auth_row">
                        <div className="card auth_card">
                            <div className="auth_img">
                                <div className="auth_img_place">
                                    <i className='bx bx-log-in'></i>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="auth_title">
                                    Авторизация
                                </div>
                                <div className="form_row">
                                    <input
                                        placeholder="Логин"
                                        type="text"
                                        id="login"
                                        name="login"
                                        value={loginData.login}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form_row">
                                    <input
                                        placeholder="Пароль"
                                        type="password"
                                        id="pass"
                                        name="pass"
                                        value={loginData.pass}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form_row">
                                    <button type="submit">Войти</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="main_auth_row">
                        {error && <SteamStatusCard message={error}/>} {/* Выводим сообщение об ошибке */}

                    </div>

                </div>
            </div>
        </main>
    );
}

export default LoginForm;
