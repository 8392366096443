import React, {useEffect, useState} from "react";
import ServerLink from "./ServerLink";

function TransactionsHeader() {
    const [isComponentVisible, setComponentVisible] = useState(false);
    const [isAnimationActive, setAnimationActive] = useState(false);
    const [isNewComponentVisible, setNewComponentVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState("");
    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState("");



    const toggleComponent = () => {
        setComponentVisible(!isComponentVisible);
    };
    const toggleNewComponent = () => {
        setNewComponentVisible(!isNewComponentVisible);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObject = {};

        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });

        const data = {
            data: formDataObject,
            object: "transactionAdd"
        };

        try {
            const response = await fetch(ServerLink().EditGameUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setAnimationActive(true);
                event.target.reset();
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            } else {
                console.error("Ошибка при отправке данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
        }
    };

    const handleSubmitWallet = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObject = {};

        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });

        const data = {
            data: formDataObject,
            object: "transactionAddWallet"
        };

        try {
            const response = await fetch(ServerLink().EditGameUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setAnimationActive(true);
                event.target.reset();
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            } else {
                console.error("Ошибка при отправке данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
        }
    };

    useEffect(() => {
        sendToServer();
    }, []);
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'accounts'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData.table);
                setAccounts(responseData.table);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);

            });
    };
    useEffect(() => {
        sendToServerGames();
    }, []);
    const sendToServerGames = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'digiGames'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData.table);
                setGames(responseData.table);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);

            });
    };
    return(
        <>
            <span>Транзакции</span>
            <span><button onClick={toggleComponent} className={`btnDataAdd bx ${isComponentVisible ? 'bx-minus' : 'bx-plus'}`}></button></span>
            <span><button onClick={toggleNewComponent} className={`btnDataAddWallet bx ${isNewComponentVisible ? 'bxs-wallet' : 'bx-wallet'}`}></button></span>
            {isComponentVisible &&(
                <div className="headerAddContent">
                    <form onSubmit={handleSubmit}>
                        <div className="headerFormRow">
                            <input className={`${isAnimationActive ? 'animation' : ''}`} required name="code" placeholder="Уникальный код" type="text"/>
                            <input className={`${isAnimationActive ? 'animation' : ''}`} required name="steamUrl" placeholder="Ссылка на steam" type="text"/>
                            <select className={`${isAnimationActive ? 'animation' : ''}`} required name="accountId" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                                <option value="" disabled>Выберите аккаунт</option>
                                {accounts.map(account => (
                                    <option key={account.id} value={account.id}>
                                        {account.login} | {account.balance} | {account.region}
                                    </option>
                                ))}
                            </select>
                            <select className={`${isAnimationActive ? 'animation' : ''}`} required name="gameId" value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
                                <option value="" disabled>Выберите игру</option>
                                {games.map(game => (
                                    <option key={game.id} value={game.id}>
                                        {game.gId} | {game.name} {/* Используйте game.name вместо game.id */}
                                    </option>
                                ))}
                            </select>
                        {/*</div>*/}
                        {/*<div className="headerFormRow">*/}

                            <button type="submit" className="headerFormButton bx bx-send"></button>
                        </div>
                    </form>
                </div>
            )}
            {isNewComponentVisible && (
                <div className="headerAddContent">
                    <form onSubmit={handleSubmitWallet}>
                        <div className="headerFormRow">
                            <input required name="code" placeholder="Уникальный код" type="text"/>
                            <input required name="login" placeholder="Логин Steam" type="text"/>
                            <input required name="amount" placeholder="Сумма в рублях" type="text"/>
                            <button type="submit" className="headerFormButton bx bx-send"></button>
                        </div>
                    </form>
                </div>
            )}
        </>
    )
}

export default TransactionsHeader;