import React, { useState, useEffect } from "react";
import Search from "./Search";
import { Link } from 'react-router-dom';
import { useAuth } from "../Components/AuthProvider"; // Импортируем useAuth

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);
    const { isAuthenticated, logout, userName } = useAuth(); // Получаем isAuthenticated и logout из провайдера
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState);
    };

    useEffect(() => {
        // Добавляем обработчик события скролла при монтировании компонента
        window.addEventListener("scroll", handleScroll);
        return () => {
            // Удаляем обработчик события скролла при размонтировании компонента
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        // Проверяем, насколько прокручена страница по вертикали
        if (window.scrollY > 20) {
            // Если страница прокручена, устанавливаем значение состояния true
            setIsScrolled(true);
        } else {
            // Если страница не прокручена (верх страницы), устанавливаем значение состояния false
            setIsScrolled(false);
        }
    };

    const handleLogout = () => {
        logout(); // Вызываем функцию logout из провайдера
    };

    return (
        <header className={isScrolled ? "scroll" : ""}>
            <div className="header">
                <div className="header_left">
                    <Link to="/">BIKS</Link>
                    {/*<Link to="/">BattleKey</Link>*/}
                </div>
                <Search/>
                <div className="header_right">
                    {isAuthenticated ? (
                        // Отображаем кнопку выхода, если пользователь авторизован
                        <>
                            <div onClick={toggleDropdown} className="drop_down">
                                <span className="drop_down_title"><i className='bx bx-dots-vertical-rounded'></i></span>
                                {showDropdown && isAuthenticated && (
                                    <div className="drop_down_content">
                                        <div className="drop_down_content_row row_title">
                                            <i className='bx bx-user'></i> {userName}
                                        </div>
                                        <div className="drop_down_content_row">
                                            <Link to="/Admin" className=""><i className='bx bx-book-content'></i>Админ панель</Link>
                                        </div>
                                        <div className="drop_down_content_row">
                                            <Link to="#" onClick={handleLogout} className="logout_button"><i className='bx bx-log-out'></i>Выйти</Link>
                                        </div>
                                </div>
                                    )}
                            </div>
                        </>
                    ) : (
                        // Отображаем кнопку информации, если пользователь не авторизован
                        <Link to="/Info" className="info_button bx bx-info-circle"></Link>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
