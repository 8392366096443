import Recommendations from "../Components/Recommendations";
import QiwiCard from "../Components/QiwiCard";
import QiwiStatusCard from "../Components/QiwiStatusCard";


function Qiwi () {

    return(
        <>
            <main>
                <div className="main_container qiwi">
                    <div className="main_gift">
                        <div className="main_row">
                            <QiwiCard/>
                            <QiwiStatusCard/>
                        </div>
                    </div>
                </div>
                <Recommendations/>
            </main>
        </>
    )
}

export default Qiwi;