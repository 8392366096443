import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import {AuthProvider} from "./Components/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthProvider>
          <App />
      </AuthProvider>
);