import React, {useEffect, useState} from "react";
import ServerLink from "./ServerLink";
import Loader from "./Loader";


function TransactionsQiwi () {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAll, setShowAll] = useState(false); // Флаг для отображения всех транзакций

    const handleShowAll = () => {
        setShowAll(true); // Устанавливаем флаг, чтобы показать все транзакции
    };
    const handleTransaction = (login, amount, code) => {
        if(window.confirm('Вы уверены что хотите выдать повторно?')){
            sendToServerData(login, amount, code);
        };
    };

    useEffect(() => {
        sendToServer();
    }, []);

    const handleRefresh = () => {
        sendToServer();
    };
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'transactionsQiwi'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                setTableData(responseData.table);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    const sendToServerData = (login, amount, code) => {
        const apiUrl = ServerLink().RetryQiwiUrl;

        const requestData = {
            object: 'RetryQiwi',
            data: {
                login: login,
                amount: amount,
                code: code
            }
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Data updated on server:', responseData);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };

    return(
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <OptimizedTable
                        handleRefresh={handleRefresh}
                        handleTransaction={handleTransaction}
                        tableData={showAll ? tableData : tableData.slice(0, 100)} // Отображаем все транзакции или только первые 100
                    />
                    {!showAll && (
                        <div className="showAll_button">
                            <button onClick={handleShowAll}>Загрузить еще</button>
                        </div> // Кнопка для загрузки остальных транзакций
                    )}
                </>
            )}
        </>
    )
}

const TableRow = React.memo(({ item, handleTransaction, handleRefresh }) => {
    const [isAnimationActive, setAnimationActive] = useState(false);

    const handleLoginEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.steam_login) {
            sendToServerData(newText, itemId, 'updateLoginWallet');
        }
    };


    const sendToServerData = (data, itemId, object) => {
        const apiUrl = ServerLink().EditGameUrl;

        const dataNew = {
            digiId: itemId,
            newDigi: data
        };

        const requestData = {
            object: object,
            data: dataNew
        };
        console.log(requestData)

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Data updated on server:', responseData);
                setAnimationActive(true);
                handleRefresh();
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Error updating data on server:', error);
            });
    };

    return(
        <>
            <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.code}</td>
                <td>
            <span
                className={`editable_cell ${isAnimationActive ? 'animation' : ''}`}
                onBlur={(event) => handleLoginEdit(event, item.id)}
                suppressContentEditableWarning={true} contentEditable
            >
                {item.steam_login}
            </span>
                </td>
                <td>{item.amount}</td>
                <td>{item.amount_usd}</td>
                <td>{item.code_api}</td>
                <td>{item.time}</td>
                <td>{item.status}</td>
                <td>
                    <button onClick={() => handleTransaction(item.steam_login, item.amount, item.code)}
                            className={'sendQiwiButton'}>Выдать
                    </button>
                </td>
            </tr>
        </>
    )
    }
);

const OptimizedTable = ({tableData, isLoading, handleRefresh, handleTransaction}) => (
    <>
        {isLoading ? (
            <Loader/>
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th>
                            <span>ID</span>
                            <span><button onClick={handleRefresh}
                                          className="bx bx-refresh headerFormButton"></button></span>
                        </th>
                        <th>Код транзакции</th>
                        <th>Логин Steam</th>
                        <th>Сумма ₽</th>
                        <th>Сумма $</th>
                        <th>Код по api</th>
                        <th>Дата</th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id} item={item} handleTransaction={handleTransaction} handleRefresh={handleRefresh} />
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
)

export default TransactionsQiwi;