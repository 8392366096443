import React, { useState, useEffect, useRef } from 'react';

const Modal = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [modalFocus, setModalFocus] = useState(false);

    const modalRef = useRef(null);

    // const handleModalFocus = () => {
    //     setModalFocus(true);
    // };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        const offsetX = e.clientX - modalPosition.x;
        const offsetY = e.clientY - modalPosition.y;
        setDragOffset({ x: offsetX, y: offsetY });
        setModalFocus(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isDragging) {
                const newX = e.clientX - dragOffset.x;
                const newY = e.clientY - dragOffset.y;
                setModalPosition({ x: newX, y: newY });
            }
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, dragOffset]);

    useEffect(() => {
        const handleClickOutsideModal = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setModalFocus(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideModal);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideModal);
        };
    }, []);

    useEffect(() => {
        if (props.isOpened) {
            setModalFocus(true);
            setModalPosition({ x: 0, y: 0 });
        }
    }, [props.isOpened]);

    return (
        <div className={`modal_background ${props.isOpened ? 'active' : ''}`}>
            <div
                // onClick={handleModalFocus}
                className={`modal ${modalFocus ? 'view' : ''}`}
                ref={modalRef}
                style={{ transform: `translate(${modalPosition.x}px, ${modalPosition.y}px)` }}
            >
                <div className="modal-header" onMouseDown={handleMouseDown}>
                    <span>{props.Title}</span>
                    <span className="close-btn bx bx-x" onClick={props.onModalClose}></span>
                </div>
                <div className="modal-content">{props.Children}</div>
            </div>
        </div>
    );
};

export default Modal;
