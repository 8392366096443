function ServerLink() {
    const ServerLinks = {
        searchUrl: 'https://bikskey.ru/enginePHP/server.php?file=Search.php',
        SteamUrl: 'https://bikskey.ru/enginePHP/server.php?file=Steam.php',
        AuthUrl: 'https://bikskey.ru/enginePHP/server.php?file=Auth.php',
        LinesUrl: 'https://bikskey.ru/enginePHP/server.php?file=Lines.php',
        TableUrl: 'https://bikskey.ru/enginePHP/server.php?file=Table.php',
        EditGameUrl: 'https://bikskey.ru/enginePHP/server.php?file=Edit.php',
        RetryTransaction: 'https://bikskey.ru/enginePHP/server.php?file=RetryTransaction.php',
        BufferUrl: 'https://bikskey.ru/enginePHP/server.php?file=buffer.php',
        QiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=Qiwi.php',
        RetryQiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=RetryQiwi.php',
    };
    // const ServerLinks = {
    //     searchUrl: 'http://localhost/engine/server.php?file=Search.php',
    //     SteamUrl: 'http://localhost/engine/server.php?file=Steam.php',
    //     AuthUrl: 'http://localhost/engine/server.php?file=Auth.php',
    //     LinesUrl: 'http://localhost/engine/server.php?file=Lines.php',
    //     TableUrl: 'http://localhost/engine/server.php?file=Table.php',
    //     EditGameUrl: 'http://localhost/engine/server.php?file=Edit.php',
    //     RetryTransaction: 'http://localhost/engine/server.php?file=RetryTransaction.php',
    //     BufferUrl: 'http://localhost/engine/server.php?file=buffer.php',
    //     QiwiUrl: 'http://localhost/engine/server.php?file=Qiwi.php',
    //     RetryQiwiUrl: 'http://localhost/engine/server.php?file=RetryQiwi.php',
    // };
    // const ServerLinks = {
    //     searchUrl: 'https://battlekeys.net/enginePHP/server.php?file=Search.php',
    //     SteamUrl: 'https://battlekeys.net/enginePHP/server.php?file=Steam.php',
    //     AuthUrl: 'https://battlekeys.net/enginePHP/server.php?file=Auth.php',
    //     LinesUrl: 'https://battlekeys.net/enginePHP/server.php?file=Lines.php',
    //     TableUrl: 'https://battlekeys.net/enginePHP/server.php?file=Table.php',
    //     EditGameUrl: 'https://battlekeys.net/enginePHP/server.php?file=Edit.php',
    //     RetryTransaction: 'https://battlekeys.net/enginePHP/server.php?file=RetryTransaction.php',
    //     BufferUrl: 'https://battlekeys.net/enginePHP/server.php?file=buffer.php',
    //     QiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=Qiwi.php',
    //     RetryQiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=RetryQiwi.php',
    // };
    return ServerLinks;
}

export default ServerLink;