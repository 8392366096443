import React, { useState } from "react";
import Status from "./Status";
import CardButtons from "./CardButtons";
import ModalContent from "./ModalContent";
import Games from "./Games";
import Accounts from "./Accounts";
import AccountsHeader from "./AccountsHeader";
import GamesHeader from "./GamesHeader";
import TransactionInfo from "./TransactionInfo";
import TransactionsHeader from "./TransactionsHeader";
import LogsButtons from "./LogsButtons";
import Proxy from "./Proxy";
import ProxyHeader from "./ProxyHeader";
import Logs from "./Logs";
import TransactionsTabs from "./TransactionsTabs";

function Control() {
    const [modal, setModal] = useState({
        transactions: false,
        games: false,
        accounts: false,
        proxy: false,
        giftLogs: false,
        parceLogs: false,
        adminLogs: false,
    });



    return (
        <main>
            <div className="dashboard">
                <div className="dash_row">
                    <Status />
                    <CardButtons modal={modal} setModal={setModal} />
                </div>
                <div className="dash_row">
                    <LogsButtons modal={modal} setModal={setModal} />
                </div>
                <div className="dash_row">
                    <TransactionInfo/>
                </div>
                <div className="dash_row">
                    <div className="account_card_list">
                        {/*<CardList />*/}
                    </div>
                </div>
            </div>
            {/* Подгрузка компонентов только после нажатия */}
            {modal.transactions && (
                <ModalContent
                    title={<TransactionsHeader/>}
                    isOpened={modal.transactions}
                    onClose={() => setModal({ ...modal, transactions: false })}
                >
                    <TransactionsTabs/>
                </ModalContent>
            )}
            {modal.games && (
                <ModalContent
                    title={<GamesHeader/>}
                    isOpened={modal.games}
                    onClose={() => setModal({ ...modal, games: false })}
                >
                    <Games/>
                </ModalContent>
            )}
            {modal.accounts && (
                <ModalContent
                    title={<AccountsHeader/>}
                    isOpened={modal.accounts}
                    onClose={() => setModal({ ...modal, accounts: false })}
                >
                    <Accounts/>
                </ModalContent>
            )}
            {modal.proxy && (
                <ModalContent
                    title={<ProxyHeader/>}
                    isOpened={modal.proxy}
                    onClose={() => setModal({ ...modal, proxy: false })}
                >
                    <Proxy/>
                </ModalContent>
            )}
            {modal.giftLogs && (
                <ModalContent
                    title='Логи выдачи'
                    isOpened={modal.giftLogs}
                    onClose={() => setModal({ ...modal, giftLogs: false })}
                >
                    <Logs logs='giftlogs' />
                </ModalContent>
            )}
            {modal.parceLogs && (
                <ModalContent
                    title='Логи парса цен'
                    isOpened={modal.parceLogs}
                    onClose={() => setModal({ ...modal, parceLogs: false })}
                >
                    <Logs logs='parceLogs' />
                </ModalContent>
            )}
            {modal.adminLogs && (
                <ModalContent
                    title='Логи действий'
                    isOpened={modal.adminLogs}
                    onClose={() => setModal({ ...modal, adminLogs: false })}
                >
                    <Logs logs='adminLogs' />
                </ModalContent>
            )}
        </main>
    );
}

export default Control;
