import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Main from "../Pages/main";
import Admin from "../Pages/admin";
import Info from "../Pages/info";
import Other from "../Pages/other";
import Gift from "../Pages/gift";
import Qiwi from "../Pages/qiwi";
import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
function App(){

    return(
        <>
            <Router>
                <Header/>
                <div className="background_element"></div>
                <Routes>
                    <Route exact path="/" element={<Main/>} />
                    <Route exact path="/admin" element={<Admin/>} />
                    <Route exact path="/info" element={<Info/>} />
                    <Route exact path="/gift" element={<Gift/>} />
                    <Route exact path="/qiwi" element={<Qiwi/>} />
                    <Route exact path="*" element={<Other/>} />
                </Routes>
                <Footer/>
            </Router>
        </>
    );
}

export default App;