
const LogsButtons = ({ modal, setModal }) => {

    return(
        <div className='card logs_card'>
            <div className="card_row logs_row">
                <button onClick={() => setModal({ ...modal, proxy: true })} className='btnProxy'>
                    <span>Прокси</span>
                </button>
                <button onClick={() => setModal({ ...modal, giftLogs: true })} className='btnLogs'>
                    <span>Логи выдачи</span>
                </button>
                <button onClick={() => setModal({ ...modal, parceLogs: true })} className='btnLogsPrice'>
                    <span>Логи парса цен</span>
                </button>
                <button onClick={() => setModal({ ...modal, adminLogs: true })} className='btnLogsAdmin'>
                    <span>Логи действий</span>
                </button>
            </div>
        </div>
    )
}

export default LogsButtons;