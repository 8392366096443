import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function Logs(logs) {
    const [tableData, setTableData] = useState([]); // Инициализируем tableData как пустой массив
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        sendToServer();
    }, []);

    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: logs.logs
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                setTableData(responseData.table);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    return (
        <>{isLoading ? (
            <Loader />
        ) : (
            <table>
                <thead>
                <tr>
                    <th>Непосредственно Лог :)</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((log, index) => (
                    <tr key={index}>
                        <td>{log}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        )}
        </>
    )
}

export default Logs;
