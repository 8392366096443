import React, { useState } from "react";
import ServerLink from "./ServerLink";
function ProxyHeader() {

    const [isComponentVisible, setComponentVisible] = useState(false);
    const [isAnimationActive, setAnimationActive] = useState(false);

    const toggleComponent = () => {
        setComponentVisible(!isComponentVisible);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObject = {};

        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });

        const data = {
            data: formDataObject,
            object: "proxyAdd"
        };

        try {
            const response = await fetch(ServerLink().EditGameUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setAnimationActive(true);
                event.target.reset();
                setTimeout(() => {
                    setAnimationActive(false);
                }, 2000);
            } else {
                console.error("Ошибка при отправке данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
        }
    };
    return(
        <>
            <span>Прокси</span>
            <span><button onClick={toggleComponent} className={`btnDataAdd bx ${isComponentVisible ? 'bx-minus' : 'bx-plus'}`}></button></span>
            {isComponentVisible &&(
                <div className="headerAddContent">
                    <form onSubmit={handleSubmit}>
                        <div className="headerFormRow">
                            <input className={`${isAnimationActive ? 'animation' : ''}`} required name="proxy" placeholder="Прокси" type="text"/>
                            <input className={`${isAnimationActive ? 'animation' : ''}`} required name="proxyTitle" placeholder="Описание" type="text"/>
                            <button type="submit" className="headerFormButton bx bx-send"></button>
                        </div>
                    </form>
                </div>
            )}
        </>
    )
}

export default ProxyHeader